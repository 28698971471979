import axios from "../axios";
import { getLogout } from "./loginAction";
import { errorMsg,successMsg, validateField } from "./customFn";
export const uplodProductNew = (
  languageReducer,
  setTourId,
  navigate, 
  formData,
  nextRoute = "",
  setOptionId = "", 
  type = "",
  setisopen = "",
  setApprovalArray = "",
  CloseAddOPtionModel,
  setIsUpload,
  setSpinLoader,
  setSlidePages = "",
  setProductInfo = "",
  languageList = "",
  resetFormdata = "",
  setsReservationMsg = "",
  optionImage,
) => {
  return async (dispatch) => {
    try {
      const currency = JSON.parse(localStorage.getItem("currency"));
      const token = localStorage.getItem("_token");

      let params = {
        user_id: localStorage.getItem("userId"),
        password: localStorage.getItem("password"),
        token: token,
        currency: currency.id,
        ...formData,
      };
      

      if (type == "images_tab" || type == "others") {
        formData.append("user_id", localStorage.getItem("userId"));
        formData.append("password", localStorage.getItem("password"));
        // formData.append("language", languageReducer.id);
        formData.append("currency", currency.id);
        formData.append("token", localStorage.getItem("_token"));
        params = formData;
      } 

      const response = await axios.post("upload-product-new", params);
      if (response.data.status === true) {
        const { tour_id } = response.data.data;
        if (setTourId !== "") {
          setTourId(response.data.data.tour_id);
        }
        if (setOptionId !== "") {
          setOptionId(response.data.data.option_id);
        }

        if (nextRoute == "my-tours") {
          navigate("/my-tours");
        } else if (nextRoute == "") {
          //Do nothing
        } else {
          navigate(`/product-bulider/${nextRoute}/${tour_id}`);
        }

        if (setisopen !== "") {
          setisopen(false);
        }
        if (setApprovalArray !== "") {
          //From My Tours
          setApprovalArray((state) => [...state, formData.tourId]);
        }

        if (CloseAddOPtionModel) {
          CloseAddOPtionModel();
        }
        if (setIsUpload) {
          setIsUpload((status) => !status);
        }
        if (setSlidePages) {
          setSlidePages(1);
        }

        if (resetFormdata) {
          resetFormdata();
        }

        // if (setProductInfo != "") {
        //     let result = languageList.find(obj => obj.id == formData.language);
        //     setProductInfo((prev) => ({
        //         ...prev,
        //         productLanguage: result.title,
        //         productLanguageId: result.id,
        //         productLanguageFlag: result.flag,
        //     }))
        // }

      } else {
        if (response.data.reservation_error !== undefined) {
          setsReservationMsg(response.data.message);
        } else {
          errorMsg("Somthing went wrong...");
        }
      }
      if (setSpinLoader) {
        setSpinLoader((status) => !status);
      }
     
      
    } catch (error) {
      
      
      errorMsg(error?.response?.data?.message);
      if (error.response.status === 401) {
        dispatch(getLogout(navigate));
        errorMsg("Somthing went wrong...");
      } else {
        if (setSpinLoader) {
          setSpinLoader((status) => !status);
        }
        validateField(error.response.data.message, { ...formData }, 1);
      }
    }
    // if (element) {
    //     element.style.display = 'block';
    // }
  };
};

//   ===================API get product data starts=================
export const getProductDataNew = (
  languageReducer,
  step,
  tour_id,
  setData,
  navigate,
  option_id,
  sendData,
  setRunningStep,
  setProductType,
  setDataLoad,
  setSpinLoader,
  setProductInfo,
  setActiveStep,
  languageIdSelected = ""
) => {
  return async (dispatch) => {
    try {
      const currency = JSON.parse(localStorage.getItem("currency"));
      const token = localStorage.getItem("_token");
      const params = {
        user_id: localStorage.getItem("userId"),
        password: localStorage.getItem("password"),
        step: step,
        tourId: tour_id,
        optionId: option_id,
        language: languageIdSelected ? languageIdSelected : languageReducer.id,
        currency: currency.id,
        token: token,
        ...sendData,
      };

      const response = await axios.post("get-product-data-new", params);
      if (response.data.status === true) {
        setData(response.data.data);
        setRunningStep(response.data.data.step);
        if (setProductType != undefined) {
          setProductType(response.data.data.ProductType);
          setProductInfo((prev) => ({
            ...prev,
            productTitle: response.data.data.Product_title
              ? response.data.data.Product_title
              : "",
            slug: response.data.data.slug ? response.data.data.slug : "",
          }));
          setActiveStep(response.data.data.active_step);
        }
        setDataLoad(true);
      } else if (
        response.data.option !== undefined &&
        response.data.option == 0
      ) {
        setDataLoad(true);
        errorMsg("Option not Found...");
        // navigate(`/tours-files/option/${tour_id}`);
      } else {
        navigate("/my-tours");
        errorMsg("Product Not Found...");
      }
      setSpinLoader((status) => !status);
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(getLogout(navigate));
        errorMsg("Somthing went wrong...");
      } else {
        errorMsg(error.response.data.message);
      }
    }
  };
};
// ===================API get product data ends===================

export const getImageUrl = (
  languageReducer,
  imageUrl,
  setSharingUrl,
  setIsURLObtained,
  setDropDownOpening
) => {
  return async (dispatch) => {
    try {
      const currency = JSON.parse(localStorage.getItem("currency"));
      const token = localStorage.getItem("_token");

      let params = {};
      const formData = new FormData();
      formData.append("image_url", imageUrl);
      formData.append("user_id", localStorage.getItem("userId"));
      formData.append("password", localStorage.getItem("password"));
      formData.append("currency", currency.id);
      formData.append("token", localStorage.getItem("_token"));
      formData.append("language", languageReducer.id);

      params = formData;

      const response = await axios.post("share-map", params);
      if (response.data.status === true) {
        setSharingUrl(response.data.data);
        setIsURLObtained(true);
        setDropDownOpening(false);
        // if (setProductInfo != "") {
        //     let result = languageList.find(obj => obj.id == formData.language);
        //     setProductInfo((prev) => ({
        //         ...prev,
        //         productLanguage: result.title,
        //         productLanguageId: result.id,
        //         productLanguageFlag: result.flag,
        //     }))
        // }
      } else {
        errorMsg("Somthing went wrong...");
      }
      // setSpinLoader((status) => !status);
    } catch (error) {
      // console.log("error", error);
      // if (error.response.status === 401) {
      //     dispatch(getLogout(navigate));
      //     errorMsg("Somthing went wrong...");
      // } else {
      //     setSpinLoader((status) => !status);
      //     validateField(error.response.data.message, { ...formData }, 1);
      // }
    }
    // if (element) {
    //     element.style.display = 'block';
    // }
  };
};

export const getTopAttractionList = (
  languageReducer,
  navigate,
  queryData,
  setTopAttractionList
) => {
  return async (dispatch) => {
    try {
      const currency = JSON.parse(localStorage.getItem("currency"));
      const token = localStorage.getItem("_token");
      const params = {
        user_id: localStorage.getItem("userId"),
        password: localStorage.getItem("password"),
        language: languageReducer.id,
        currency: currency.id,
        token: token,
        ...queryData,
      };

      const response = await axios.post("product-top-attraction", params);
      if (response.data.status == true) {
        setTopAttractionList(response.data.data.top_attraction);
      } else {
        errorMsg("Somthing went wrong...");
      }
    } catch (error) {
      navigate("/something-went-wrong");
    }
    // setTimeout(() => {
    //   setLoading(true)
    // }, 100)
  };
};

// Get All Reservation System List
export const getReservationSystem = (
  languageReducer,
  navigate,
  setReservationSystemList
) => {
  return async (dispatch) => {
    try {
      const currency = JSON.parse(localStorage.getItem("currency"));
      const token = localStorage.getItem("_token");
      const params = {
        user_id: localStorage.getItem("userId"),
        password: localStorage.getItem("password"),
        language: languageReducer.id,
        currency: currency.id,
        token: token,
      };

      const response = await axios.post("get-reservation-system", params);
      if (response.data.status == true) {
        setReservationSystemList(response.data.data);
      } else {
        errorMsg("Somthing went wrong...");
      }
    } catch (error) {
      navigate("/something-went-wrong");
    }
  };
};

// Check Reservation product is valid

export const getReservationProduct = async (
  languageReducer,
  navigate,
  reservation_data,
  setsReservationMsg,
  setSpinLoader
) => {
  try {
    const currency = JSON.parse(localStorage.getItem("currency"));
    const token = localStorage.getItem("_token");
    const params = {
      user_id: localStorage.getItem("userId"),
      password: localStorage.getItem("password"),
      language: languageReducer.id,
      currency: currency.id,
      token: token,
      ...reservation_data,
    };

    const response = await axios.post("validate-product-reservation", params);
    // if (response.data == true) {
    //   //   setReservationSystemList(response.data.data);
    // } else {
    //   //   errorMsg("Somthing went wrong...");
    // }
    setsReservationMsg(response.data.message);
    setSpinLoader((status) => !status);
    return response.data.status;
  } catch (error) {
    navigate("/something-went-wrong");
  }
};

// Uploade Image for Option
export const optionMapImage = ({ option_id, file,languageReducer }) => { 
  return async (dispatch) => {
    try {
      const currency = JSON.parse(localStorage.getItem("currency"));
      const token = localStorage.getItem("_token");

      // Create FormData and append the Blob data
      const formData = new FormData();
      formData.append("option_id", option_id);
      formData.append("image_url", file);
      formData.append("user_id", localStorage.getItem("userId"));
      formData.append("password", localStorage.getItem("password"));
      formData.append("currency", currency.id);
      formData.append("token", token);
      formData.append("language", 1);

      // Post the data
      const response = await axios.post("option-map", formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

  
    } catch (error) {
      console.log(error);
    }
  };
};

// Add New City Name to DB
export const AddNewCity = (cityData,setIsLoading,languageReducer) => {
  return async (dispatch) => {
    try {
      setIsLoading(true);
      const currency = JSON.parse(localStorage.getItem("currency"));
      const token = localStorage.getItem("_token");

      const formData = new FormData();
      formData.append("city_name", cityData?.city_name);
      formData.append("state_id", cityData?.state_id);
      formData.append("country_id", cityData?.country_id);
      formData.append("user_id", localStorage.getItem("userId"));
      formData.append("password", localStorage.getItem("password"));
      formData.append("currency", currency.id);
      formData.append("token", token);
      formData.append("language", languageReducer.id);

      const response = await axios.post("/add_new_city", formData);
      const { data } = response;
      if(data?.status){
        setIsLoading(false);
        successMsg(data?.message);
        return data;
      }else{
        setIsLoading(false);
        errorMsg(data?.message);
      }
    } catch (error) {
      setIsLoading(false);
      errorMsg(error?.message);
    }
  };
};
