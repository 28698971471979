import { Fragment, useContext, useEffect, useState } from "react";
import { PrincingScheduleContext } from "../../../context";
import { useTranslation } from "react-i18next";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel"; 
// import Switch from "react-switch";
// import { Link, useParams } from "react-router-dom";
// import { Select, MenuItem } from "@mui/material";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faTimesCircle } from "@fortawesome/fontawesome-free-regular";
import { faPlus, faTimes } from "@fortawesome/fontawesome-free-solid";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import { TextField, IconButton, Autocomplete } from "@mui/material";
import { getReservationSystem } from "../../../actions/productBuilderAction";
import { useDispatch, useSelector } from "react-redux";

const PricingScheduleStepTwo = () => {
  const { t } = useTranslation();
  const { tour_id, option_id } = useParams();
  const { formData, setFormData, formErrors, reservationMsg,allReservationsDates } = useContext(
    PrincingScheduleContext
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [reservationSystemList, setReservationSystemList] = useState([]);
  const languageReducer = useSelector((state) => state.languageReducer);

  useEffect(() => {
    dispatch(
      getReservationSystem(languageReducer, navigate, setReservationSystemList)
    );
  }, []);

  const handleChange = (event) => {
    const { value, name, checked } = event.target;
    let pricesIndividual = {
      adult: {
        age_from: 0,
        age_to: 99,
        booking_category: "standard",
        no_of_people: [
          {
            from: 1,
            to: 51,
          },
        ],
        retail_price: [0],
        commission: [30],
        payout_per_person: [0],
      },
    };

    let pricesGroup = {
      group: {
        age_from: 0,
        age_to: 0,
        booking_category: "none",
        no_of_people: [
          {
            from: 1,
            to: 51,
          },
        ],
        retail_price: [0],
        commission: [30],
        payout_per_person: [0],
      },
    };

    if (name == "is_time_slot_select" || name == "is_fixed_time") {
      setFormData((prev) => ({
        ...prev,
        [name]: checked == true ? "yes" : "no",
      }));
    } else if (name == "use_availibilty") {
      if(value == "no")
      {
        setFormData((prev) => ({
          ...prev,
          ['unique_availiblity_date']: [],[name]: value
        }));
      }else{
        setFormData((prev) => ({
          ...prev,
          ['unique_availiblity_date']: allReservationsDates,[name]: value
        }));
      }
     

    } else if (name == "pricing_type") {
      // if (formData.pricing_list != "") {
      Swal.fire({
        title: t("_change_this_pricing_system"),
        text: t(
          "_This_will_delete_the_pricing_you_have_already_set_up_for_this_option"
        ),
        type: "warning",
        showCancelButton: true,
        cancelButtonText: t("_cancel"),
        confirmButtonColor: "rgb(155 176 199)",
        cancelButtonColor: "rgb(227 46 46)",
        confirmButtonText: t("_yes_delete_it"),
      }).then((res) => {
        if (res.isConfirmed === true) {
          setFormData((prev) => ({
            ...prev,
            [name]: value,
            prices:
              value == "person"
                ? pricesIndividual
                : value == "group"
                ? pricesGroup
                : pricesIndividual,
          }));
          // setPricingType(value);
          // setOptionPricingType("pricing");
          const data = {
            tourId: tour_id,
            optionId: option_id,
          };
          // dispatch(UpdatePricingType(languageReducer, data));
          // setShowButton(true);
          // setFormData((prev) => ({ ...prev, pricing_list: "" }));
        }
      });
      // } else {
      //   setFormData((prev) => ({ ...prev, [name]: value }));
      //   setPricingType(value);
      // }
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleDate = (date, key, availibityType) => {
    let formatedDate = moment(date).format("yyyy-MM-DD");
  
    if (key == "validity_from" || key == "validity_to") {
      setFormData((prev) => ({ ...prev, [key]: date ? formatedDate : "" }));
    } else {
      // let newstate = [...formData.unique_availiblity_date];
      let newstate = [...formData[availibityType]];
      newstate[key]["date"] = formatedDate;
      setFormData((prev) => ({
        ...prev,
        availibityType: newstate,
      }));
    }
    // setFormData((prev) => ({ ...prev, [key]: date }));
  };

  const setSelectedTime = (availibityType, e, key, day, type, newKey) => {
    const { name, value } = e.target;
    if (type == "day") {
      let newstate = [...formData[availibityType][day]];
      if (newKey) {
        if (newKey) {
          newstate[key][newKey][name] = value; //for fixed time slot
        } else {
          newstate[key][name] = value;
        }
      }
      newstate[key][name] = value;
      setFormData((prev) => ({
        ...prev,
        availibityType: {
          ...prev[availibityType],
          [day]: newstate,
        },
      }));
    } else {
      let newstate = [...formData[availibityType]];
      if (newKey) {
        newstate[key]["time"][day][newKey][name] = value; //for fixed time slot
      } else {
        newstate[key]["time"][day][name] = value;
      }

      setFormData((prev) => ({
        ...prev,
        [availibityType]: newstate,
      }));
    }
  };

  const GetHours = (val) => {
    var HourOptions = [];
    for (let index = 0; index < 24; index++) {
      HourOptions.push(
        <option selected={index == val.val} value={index} key={index}>
          {index}
        </option>
      );
    }

    return HourOptions;
  };

  const GetMinutes = (val) => {
    var MinuteOptions = [];
    for (let index = 0; index <= 11; index++) {
      MinuteOptions.push(
        <option selected={index * 5 == val.val} value={index * 5} key={index}>
          {index * 5}
        </option>
      );
    }

    return MinuteOptions;
  };

  const removeWeakTime = (key, day, newKey) => {
    if (newKey == "fixed_availiblity") {
      formData.fixed_availiblity[day].splice(key, 1);
    }
    if (newKey == "weekly_availiblity") {
      formData.weekly_availiblity[day].splice(key, 1);
    }
    setFormData((prev) => ({ ...prev, formData }));
  };

  const removeUniqueDayTime = (dateIndex, timeIndex, newKey) => {
    if (newKey == "fixed_availiblity") {
      formData.fixed_unique_availiblity_date[dateIndex].time.splice(
        timeIndex,
        1
      );
    }
    if (newKey == "weekly_availiblity") {
      formData.unique_availiblity_date[dateIndex].time.splice(timeIndex, 1);
    }
    setFormData((prev) => ({ ...prev, formData }));
  };

  const handleWeeklyTime = (day, type, availibityType) => {
    if (type == "day") {
      if (availibityType == "weekly_availiblity") {
        setFormData((prev) => ({
          ...prev,
          weekly_availiblity: {
            ...prev.weekly_availiblity,
            [day]: [...prev.weekly_availiblity[day], { hr: 0, mi: 0 }],
          },
        }));
      }

      if (availibityType == "fixed_availiblity") {
        setFormData((prev) => ({
          ...prev,
          fixed_availiblity: {
            ...prev.fixed_availiblity,
            [day]: [
              ...prev.fixed_availiblity[day],
              { from: { hr: 0, mi: 0 }, to: { hr: 0, mi: 0 } },
            ],
          },
        }));
      }
    } else {
      let newstate = [];
      // let newstate = [...formData.unique_availiblity_date];
      if (availibityType == "weekly_availiblity") {
        newstate = [...formData.unique_availiblity_date];
        let newstate2 = [...newstate[day]["time"], { hr: 0, mi: 0 }];
        newstate[day]["time"] = newstate2;
      }
      if (availibityType == "fixed_availiblity") {
        newstate = [...formData.fixed_unique_availiblity_date];
        let newstate2 = [
          ...newstate[day]["time"],
          { from: { hr: 0, mi: 0 }, to: { hr: 0, mi: 0 } },
        ];
        newstate[day]["time"] = newstate2;
      }
      setFormData((prev) => ({
        ...prev,
        unique_availiblity_date: newstate,
      }));
    }
  };

  // const handleFixedTime = (day, type) => {
  //   console.log("day", day, type);
  //   if (type == "day") {
  //     setFormData((prev) => ({
  //       ...prev,
  //       fixed_availiblity: {
  //         ...prev.fixed_availiblity,
  //         [day]: [...prev.fixed_availiblity[day], { from: { hr: 0, mi: 0 }, to: { hr: 0, mi: 0 } }],
  //       },
  //     }));
  //   } else {
  //     let newstate = [...formData.unique_availiblity_date];
  //     let newstate2 = [...newstate[day]["time"], { hr: 0, mi: 0 }];

  //     newstate[day]["time"] = newstate2;
  //     setFormData((prev) => ({
  //       ...prev,
  //       unique_availiblity_date: newstate,
  //     }));
  //   }
  // };

  // const setSelectedFixedTime = (e, key, day, type, newKey) => {
  //   const { name, value } = e.target;
  //   if (type == "day") {
  //     let newstate = [...formData.fixed_availiblity[day]];
  //     newstate[key][newKey][name] = value;
  //     setFormData((prev) => ({
  //       ...prev,
  //       weekly_availiblity: {
  //         ...prev.weekly_availiblity,
  //         [day]: newstate,
  //       },
  //     }));
  //   } else {
  //     let newstate = [...formData.unique_availiblity_date];
  //     newstate[key]["time"][day][name] = value;

  //     setFormData((prev) => ({
  //       ...prev,
  //       unique_availiblity_date: newstate,
  //     }));
  //   }
  // };

  // console.log("formData",formData.weekly_availiblity);

  function removeNewDate(index, availibityType) {
    if (availibityType == "weekly_availiblity") {
      formData.unique_availiblity_date.splice(index, 1);
    }
    if (availibityType == "fixed_availiblity") {
      formData.fixed_unique_availiblity_date.splice(index, 1);
    }
    setFormData((prev) => ({ ...prev, formData }));
  }

  const addNewDate = (availibityType) => {
    const date = moment().format("yyyy-MM-DD");
    if (availibityType == "unique_availiblity_date") {
      let newstate = [...formData.unique_availiblity_date];
      let newstate2 = [
        ...newstate,
        {
          date: date,
          time: [{ hr: 0, mi: 0 }],
        },
      ];

      setFormData((prev) => ({
        ...prev,
        [availibityType]: newstate2,
      }));
    }

    if (availibityType == "fixed_unique_availiblity_date") {
      let newstate = [...formData.fixed_unique_availiblity_date];
      let newstate2 = [
        ...newstate,
        {
          date: date,
          time: [{ from: { hr: 0, mi: 0 }, to: { hr: 0, mi: 0 } }],
        },
      ];

      setFormData((prev) => ({
        ...prev,
        [availibityType]: newstate2,
      }));
    }
  };


  const handleBookingSystem = (option) => {
    setFormData((prev) => ({
      ...prev, 
      booking_system: option ? option.id : "",
    }));
  };


  return (
    <div className="inclusion_models pricing_step_two">
      <h2>{t("_Step")} 2 </h2>
      <h1>{t("_Create_a_pricing_schedule")}</h1>
      <div className="pricing_section">
        <div className="pricing_section_main">
          <div className="pricing_section_heading">
            <div className="radio_btn_option booking-system-main">
              <div className="radio_btn_transpoetantion option_pricing_div">
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">
                    <h5>Do you use a booking system for your availibility ?</h5>
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="no"
                      name="use_availibilty"
                      checked={formData.use_availibilty == "no"}
                      control={<Radio />}
                      label={t("_No")}
                    />

                    <FormControlLabel
                      value="yes"
                      name="use_availibilty"
                      checked={formData.use_availibilty == "yes"}
                      control={<Radio />}
                      label={t("_Yes")}
                    />

                    {formData.use_availibilty == "yes" && (
                      <div className="product_activity_info">
                        <div className="booking-system-div">
                          <h6>{t("_Booking_system")}</h6>
                          {reservationSystemList?.length && (
                            <Autocomplete
                              name="booking_system"
                              options={reservationSystemList}
                              getOptionLabel={(option) => option.name}
                              onChange={(e, option) =>
                                handleBookingSystem(option)
                              }
                              value={reservationSystemList.find(
                                (item) => item.id == formData.booking_system
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                      <IconButton
                                        type="submit"
                                        aria-label="search"
                                      ></IconButton>
                                    ),
                                  }}
                                />
                              )}
                            />
                          )}
                        </div>

                        <h6>{t("_External_Product_Id")}</h6>
                        <div>
                          <input
                            type="text"
                            className="external-product-id-input"
                            id="external_product_id"
                            name="external_product_id"
                            onChange={handleChange}
                            // onClick={handleTooltipOpen}
                            value={formData.external_product_id}
                          />
                        </div>

                        <div className="radio_btn_transpoetantion">
                          <h6 name="configure_automatically">
                            What do you want to configure automatically ?
                          </h6>
                          <FormControl>
                            <FormLabel id="demo-radio-buttons-group-label"></FormLabel>
                            <RadioGroup
                              aria-labelledby="demo-radio-buttons-group-label"
                              name="configure_automatically"
                              onChange={handleChange}
                            >
                              <FormControlLabel
                                value="availabilty_and_price"
                                control={<Radio />}
                                label="Availabilty and price"
                                checked={
                                  formData.configure_automatically ==
                                  "availabilty_and_price"
                                    ? true
                                    : false
                                }
                              />
                              <FormControlLabel
                                value="availabilty_only"
                                control={<Radio />}
                                label="Availabilty only"
                                checked={
                                  formData.configure_automatically ==
                                  "availabilty_only"
                                    ? true
                                    : false
                                }
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>
                      </div>
                    )}
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
          </div>
        </div>
        {reservationMsg != "" && (
          <div className="reservation_msg">
            <span>{reservationMsg}</span>
          </div>
        )}
      </div>

      {formData.use_availibilty == "yes" &&
        formData.unique_availiblity_date.length > 0 && (
          <div
            className="reservation-table"
            // style={{ height: formData.unique_availiblity_date.length > 5 ? 500 : 10 }}
          >
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th className="date" scope="col">
                    Date
                  </th>
                  <th scope="col">Time</th>
                </tr>
              </thead>
              <tbody>
                {formData.unique_availiblity_date.map((value, index) => {
                  return (
                    <tr>
                      <td>{value.date}</td>
                      <td>
                        {value.time.map((item, index2) => {
                          return (
                            <tr rowSpan={3}>
                              <span>
                                {item.hr + " : " + item.mi + " "}
                                {value.time.length - 1 != index2 && (
                                  <>, {"  "}</>
                                )}
                              </span>
                            </tr>
                          );
                        })}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}

      {formData.configure_automatically != "availabilty_and_price" && (
        <div className="pricing_section mt-2">
          <div className="pricing_section_main">
            <div className="pricing_section_heading">
              {/* <h1>{t('_Pricing_and_availability')}</h1> */}
              <div className="radio_btn_option">
                <div className="radio_btn_transpoetantion option_pricing_div">
                  <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">
                      <h5>{t("_What_type_of_pricing_do_you_use?")}</h5>
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="person"
                        name="pricing_type"
                        checked={formData.pricing_type == "person"}
                        control={<Radio />}
                        label={t("_Price_Per_Person")}
                      />
                      <span className="pricing_span">
                        {t(
                          "_Lets_you_offer_different_prices_for_adults_teenagers_children_etc_Use_this_for_public_activities"
                        )}
                      </span>
                      <FormControlLabel
                        value="group"
                        name="pricing_type"
                        checked={formData.pricing_type == "group"}
                        control={<Radio />}
                        label={t("_Price_Per_Group")}
                      />
                      <span className="pricing_span">
                        {t(
                          "_Customers_pay_one_price_for_their_entire_group_This_is_often_used_for_private_activities"
                        )}
                      </span>
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
            </div>

            <div className="pricing_text">
              <p>
                {t(
                  "_To_charge_each_person_individually_as_well_create_a_new_option_after_you_submit_your_activity_for_our_team_to_review"
                )}
              </p>
            </div>
          </div>
        </div>
      )}

      {formData.use_availibilty == "no" && (
        <>
          <div className="priceDateSection">
            <div>
              <h6>{t("_When_does_your_schedule_start?")}</h6>
              <div className="price_datepicker">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_106_2)">
                      <path
                        d="M15.375 10.3125C15.375 10.1633 15.4343 10.0202 15.5398 9.91475C15.6452 9.80926 15.7883 9.75 15.9375 9.75H17.0625C17.2117 9.75 17.3548 9.80926 17.4602 9.91475C17.5657 10.0202 17.625 10.1633 17.625 10.3125V11.4375C17.625 11.5867 17.5657 11.7298 17.4602 11.8352C17.3548 11.9407 17.2117 12 17.0625 12H15.9375C15.7883 12 15.6452 11.9407 15.5398 11.8352C15.4343 11.7298 15.375 11.5867 15.375 11.4375V10.3125ZM12 10.3125C12 10.1633 12.0593 10.0202 12.1648 9.91475C12.2702 9.80926 12.4133 9.75 12.5625 9.75H13.6875C13.8367 9.75 13.9798 9.80926 14.0852 9.91475C14.1907 10.0202 14.25 10.1633 14.25 10.3125V11.4375C14.25 11.5867 14.1907 11.7298 14.0852 11.8352C13.9798 11.9407 13.8367 12 13.6875 12H12.5625C12.4133 12 12.2702 11.9407 12.1648 11.8352C12.0593 11.7298 12 11.5867 12 11.4375V10.3125ZM6.375 13.6875C6.375 13.5383 6.43426 13.3952 6.53975 13.2898C6.64524 13.1843 6.78832 13.125 6.9375 13.125H8.0625C8.21168 13.125 8.35476 13.1843 8.46025 13.2898C8.56574 13.3952 8.625 13.5383 8.625 13.6875V14.8125C8.625 14.9617 8.56574 15.1048 8.46025 15.2102C8.35476 15.3157 8.21168 15.375 8.0625 15.375H6.9375C6.78832 15.375 6.64524 15.3157 6.53975 15.2102C6.43426 15.1048 6.375 14.9617 6.375 14.8125V13.6875ZM9.75 13.6875C9.75 13.5383 9.80926 13.3952 9.91475 13.2898C10.0202 13.1843 10.1633 13.125 10.3125 13.125H11.4375C11.5867 13.125 11.7298 13.1843 11.8352 13.2898C11.9407 13.3952 12 13.5383 12 13.6875V14.8125C12 14.9617 11.9407 15.1048 11.8352 15.2102C11.7298 15.3157 11.5867 15.375 11.4375 15.375H10.3125C10.1633 15.375 10.0202 15.3157 9.91475 15.2102C9.80926 15.1048 9.75 14.9617 9.75 14.8125V13.6875Z"
                        fill="#FC5301"
                      />
                      <path
                        d="M6.9375 3C7.08668 3 7.22976 3.05926 7.33525 3.16475C7.44074 3.27024 7.5 3.41332 7.5 3.5625V4.125H16.5V3.5625C16.5 3.41332 16.5593 3.27024 16.6648 3.16475C16.7702 3.05926 16.9133 3 17.0625 3C17.2117 3 17.3548 3.05926 17.4602 3.16475C17.5657 3.27024 17.625 3.41332 17.625 3.5625V4.125H18.75C19.3467 4.125 19.919 4.36205 20.341 4.78401C20.7629 5.20597 21 5.77826 21 6.375V18.75C21 19.3467 20.7629 19.919 20.341 20.341C19.919 20.7629 19.3467 21 18.75 21H5.25C4.65326 21 4.08097 20.7629 3.65901 20.341C3.23705 19.919 3 19.3467 3 18.75V6.375C3 5.77826 3.23705 5.20597 3.65901 4.78401C4.08097 4.36205 4.65326 4.125 5.25 4.125H6.375V3.5625C6.375 3.41332 6.43426 3.27024 6.53975 3.16475C6.64524 3.05926 6.78832 3 6.9375 3ZM4.125 7.5V18.75C4.125 19.0484 4.24353 19.3345 4.4545 19.5455C4.66548 19.7565 4.95163 19.875 5.25 19.875H18.75C19.0484 19.875 19.3345 19.7565 19.5455 19.5455C19.7565 19.3345 19.875 19.0484 19.875 18.75V7.5H4.125Z"
                        fill="#FC5301"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_106_2">
                        <rect
                          width="18"
                          height="18"
                          fill="white"
                          transform="translate(3 3)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
                <Flatpickr
                  //   data-enable-time
                  className="date_picker"
                  placeholder="Select date"
                  id="validity_from"
                  name="validity_from"
                  value={formData.validity_from}
                  options={{
                    // minDate: "today",
                    dateFormat: "Y-m-d",
                    monthSelectorType: "static",
                  }}
                  onChange={(value) => handleDate(value[0], "validity_from")}
                />
              </div>
            </div>

            <div>
              <h6>
                {t("_When_does_your_schedule_end?")}{" "}
                <span>({t("_optional")})</span>{" "}
              </h6>
              <div className="price_datepicker ">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_106_2)">
                      <path
                        d="M15.375 10.3125C15.375 10.1633 15.4343 10.0202 15.5398 9.91475C15.6452 9.80926 15.7883 9.75 15.9375 9.75H17.0625C17.2117 9.75 17.3548 9.80926 17.4602 9.91475C17.5657 10.0202 17.625 10.1633 17.625 10.3125V11.4375C17.625 11.5867 17.5657 11.7298 17.4602 11.8352C17.3548 11.9407 17.2117 12 17.0625 12H15.9375C15.7883 12 15.6452 11.9407 15.5398 11.8352C15.4343 11.7298 15.375 11.5867 15.375 11.4375V10.3125ZM12 10.3125C12 10.1633 12.0593 10.0202 12.1648 9.91475C12.2702 9.80926 12.4133 9.75 12.5625 9.75H13.6875C13.8367 9.75 13.9798 9.80926 14.0852 9.91475C14.1907 10.0202 14.25 10.1633 14.25 10.3125V11.4375C14.25 11.5867 14.1907 11.7298 14.0852 11.8352C13.9798 11.9407 13.8367 12 13.6875 12H12.5625C12.4133 12 12.2702 11.9407 12.1648 11.8352C12.0593 11.7298 12 11.5867 12 11.4375V10.3125ZM6.375 13.6875C6.375 13.5383 6.43426 13.3952 6.53975 13.2898C6.64524 13.1843 6.78832 13.125 6.9375 13.125H8.0625C8.21168 13.125 8.35476 13.1843 8.46025 13.2898C8.56574 13.3952 8.625 13.5383 8.625 13.6875V14.8125C8.625 14.9617 8.56574 15.1048 8.46025 15.2102C8.35476 15.3157 8.21168 15.375 8.0625 15.375H6.9375C6.78832 15.375 6.64524 15.3157 6.53975 15.2102C6.43426 15.1048 6.375 14.9617 6.375 14.8125V13.6875ZM9.75 13.6875C9.75 13.5383 9.80926 13.3952 9.91475 13.2898C10.0202 13.1843 10.1633 13.125 10.3125 13.125H11.4375C11.5867 13.125 11.7298 13.1843 11.8352 13.2898C11.9407 13.3952 12 13.5383 12 13.6875V14.8125C12 14.9617 11.9407 15.1048 11.8352 15.2102C11.7298 15.3157 11.5867 15.375 11.4375 15.375H10.3125C10.1633 15.375 10.0202 15.3157 9.91475 15.2102C9.80926 15.1048 9.75 14.9617 9.75 14.8125V13.6875Z"
                        fill="#FC5301"
                      />
                      <path
                        d="M6.9375 3C7.08668 3 7.22976 3.05926 7.33525 3.16475C7.44074 3.27024 7.5 3.41332 7.5 3.5625V4.125H16.5V3.5625C16.5 3.41332 16.5593 3.27024 16.6648 3.16475C16.7702 3.05926 16.9133 3 17.0625 3C17.2117 3 17.3548 3.05926 17.4602 3.16475C17.5657 3.27024 17.625 3.41332 17.625 3.5625V4.125H18.75C19.3467 4.125 19.919 4.36205 20.341 4.78401C20.7629 5.20597 21 5.77826 21 6.375V18.75C21 19.3467 20.7629 19.919 20.341 20.341C19.919 20.7629 19.3467 21 18.75 21H5.25C4.65326 21 4.08097 20.7629 3.65901 20.341C3.23705 19.919 3 19.3467 3 18.75V6.375C3 5.77826 3.23705 5.20597 3.65901 4.78401C4.08097 4.36205 4.65326 4.125 5.25 4.125H6.375V3.5625C6.375 3.41332 6.43426 3.27024 6.53975 3.16475C6.64524 3.05926 6.78832 3 6.9375 3ZM4.125 7.5V18.75C4.125 19.0484 4.24353 19.3345 4.4545 19.5455C4.66548 19.7565 4.95163 19.875 5.25 19.875H18.75C19.0484 19.875 19.3345 19.7565 19.5455 19.5455C19.7565 19.3345 19.875 19.0484 19.875 18.75V7.5H4.125Z"
                        fill="#FC5301"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_106_2">
                        <rect
                          width="18"
                          height="18"
                          fill="white"
                          transform="translate(3 3)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
                <div className="validity_to-div">
                  <Flatpickr
                    //   data-enable-time
                    className="date_picker"
                    placeholder="Select date"
                    id="validity_to"
                    name="validity_to"
                    value={formData.validity_to}
                    options={{
                      minDate: formData.validity_from
                        ? new Date(formData.validity_from).fp_incr(1)
                        : "",
                      dateFormat: "Y-m-d",
                      monthSelectorType: "static",
                      disable: [
                        function (date) {
                          return formData.validity_from ? false : true;
                        },
                      ],
                    }}
                    onChange={(value) => handleDate(value[0], "validity_to")}
                  />
                  <button
                    onClick={() => handleDate("", "validity_to")}
                    className="reset-button-validity_to"
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div>
            <p className="availbilty-note mt-2">
              {t(
                "_Product_will_be_available_for_all_dates_if_you_select_end_date_before_today"
              )}
            </p>
          </div>
          {/* 
          <div className="checkbox_product_title">
            <input
              className="form-check-input"
              type="checkbox"
              name="is_time_slot_select"
              checked={formData.is_time_slot_select === "yes" ? true : false}
              onChange={handleChange}
            />
            <span>{t("_Do_you_want_to_select_time_slot_for_the_date_and_time")}</span>
          </div> */}

          <div className="checkbox_product_title">
            <input
              className="form-check-input"
              type="checkbox"
              name="is_fixed_time"
              checked={formData.is_fixed_time === "yes" ? true : false}
              onChange={handleChange}
            />
            <span>Do you want fixed time ?</span>
          </div>
          {
            // formData.is_time_slot_select == "yes" && (
            formData.is_fixed_time == "yes" ? (
              <>
                <div className="week_time_table_watch">
                  <div className="week_time_table">
                    {/* <h6>{t("_weekly_starting_times")}</h6> */}
                    <h6>Weekly Fixed Starting Times</h6>
                    <ul>
                      {formData.fixed_availiblity &&
                        Object.entries(formData.fixed_availiblity).map(
                          (item, index) => {
                            return (
                              <div key={index}>
                                <li className="text-body">
                                  <div className="row">
                                    <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1">
                                      <span>
                                        {" "}
                                        {item[0].charAt(0).toUpperCase() +
                                          item[0].slice(1)}
                                      </span>
                                    </div>
                                    <div className="col-xl-11 col-lg-11 col-md-11 col-sm-11 col-11">
                                      {item[1].length > 0 &&
                                        item[1].map((value, key) => {
                                          return (
                                            <div
                                              key={key}
                                              className="select-main-day"
                                            >
                                              {t("_hr")}
                                              <select
                                                name="hr"
                                                onChange={(e) =>
                                                  setSelectedTime(
                                                    "fixed_availiblity",
                                                    e,
                                                    key,
                                                    item[0],
                                                    "day",
                                                    "from"
                                                  )
                                                }
                                              >
                                                <GetHours val={value.from.hr} />
                                              </select>
                                              :{t("_min")}
                                              <select
                                                name="mi"
                                                onChange={(e) =>
                                                  setSelectedTime(
                                                    "fixed_availiblity",
                                                    e,
                                                    key,
                                                    item[0],
                                                    "day",
                                                    "from"
                                                  )
                                                }
                                              >
                                                <GetMinutes
                                                  val={value.from.mi}
                                                />
                                              </select>
                                              <span className="fixed_time-title">
                                                To
                                              </span>
                                              {t("_hr")}
                                              <select
                                                name="hr"
                                                onChange={(e) =>
                                                  setSelectedTime(
                                                    "fixed_availiblity",
                                                    e,
                                                    key,
                                                    item[0],
                                                    "day",
                                                    "to"
                                                  )
                                                }
                                              >
                                                <GetHours val={value.to.hr} />
                                              </select>
                                              :{t("_min")}
                                              <select
                                                name="mi"
                                                onChange={(e) =>
                                                  setSelectedTime(
                                                    "fixed_availiblity",
                                                    e,
                                                    key,
                                                    item[0],
                                                    "day",
                                                    "to"
                                                  )
                                                }
                                              >
                                                <GetMinutes val={value.to.mi} />
                                              </select>
                                              <span
                                                className="m-0 cursor-pointer"
                                                onClick={() =>
                                                  removeWeakTime(
                                                    key,
                                                    item[0],
                                                    "fixed_availiblity"
                                                  )
                                                }
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="24"
                                                  height="24"
                                                  viewBox="0 0 24 24"
                                                  fill="none"
                                                >
                                                  <path
                                                    d="M9.14453 9.42871H10.4302V17.143H9.14453V9.42871Z"
                                                    fill="#fc5301"
                                                  ></path>
                                                  <path
                                                    d="M13 9.42871H14.2857V17.143H13V9.42871Z"
                                                    fill="#fc5301"
                                                  ></path>
                                                  <path
                                                    d="M4 5.57129V6.857H5.28571V19.7141C5.28571 20.0551 5.42117 20.3822 5.66229 20.6233C5.90341 20.8644 6.23044 20.9999 6.57143 20.9999H16.8571C17.1981 20.9999 17.5252 20.8644 17.7663 20.6233C18.0074 20.3822 18.1429 20.0551 18.1429 19.7141V6.857H19.4286V5.57129H4ZM6.57143 19.7141V6.857H16.8571V19.7141H6.57143Z"
                                                    fill="#fc5301"
                                                  ></path>
                                                  <path
                                                    d="M9.14453 3H14.2874V4.28571H9.14453V3Z"
                                                    fill="#fc5301"
                                                  ></path>
                                                </svg>
                                              </span>
                                            </div>
                                          );
                                        })}
                                      {/* </div> */}
                                      {item[1].length == 0 && (
                                        <p
                                          className="cursor-pointer"
                                          onClick={(e) =>
                                            handleWeeklyTime(
                                              item[0],
                                              "day",
                                              "fixed_availiblity"
                                            )
                                          }
                                        >
                                          <FontAwesomeIcon icon={faClock} />{" "}
                                          {/* {t("_add_starting_time")} */}
                                          Add Fixed Time
                                        </p>
                                      )}
                                    </div>
                                  </div>

                                  {/* <div> */}
                                </li>
                              </div>
                            );
                          }
                        )}
                    </ul>
                  </div>
                </div>

                <div className="special_dates">
                  <h6>{t("_single_special_dates")}</h6>
                </div>
                <div className="pricingSchedule_special_dates">
                  {formData.fixed_unique_availiblity_date !== undefined && (
                    <div className="div-hight">
                      {Object.entries(
                        formData.fixed_unique_availiblity_date
                      ).map((item, index) => {
                        return (
                          <div
                            className="valid_form  single-special-dates position-relative"
                            key={index}
                          >
                            <div className="pic_dates account_calender">
                              {Object.entries(item[1]).map((val, key) => {
                                return (
                                  <Fragment key={key}>
                                    {val[0] == "date" && (
                                      <Flatpickr
                                        className="date_picker"
                                        placeholder="Select date"
                                        id="validity_from"
                                        name="validity_from"
                                        value={val[1]}
                                        options={{
                                          dateFormat: "Y-m-d",
                                          monthSelectorType: "static",
                                          // minDate: "today"
                                        }}
                                        onChange={(value) =>
                                          handleDate(
                                            value[0],
                                            index,
                                            "fixed_unique_availiblity_date"
                                          )
                                        }
                                      />
                                    )}

                                    {/* <div> */}
                                    {val[0] == "time" && (
                                      <div>
                                        {val[1].length > 0 &&
                                          val[1].map((value, key) => {
                                            return (
                                              <div
                                                key={key}
                                                className="select-main-day-special-dates"
                                              >
                                                {t("_hr")}
                                                <select
                                                  name="hr"
                                                  onChange={(e) =>
                                                    setSelectedTime(
                                                      "fixed_unique_availiblity_date",
                                                      e,
                                                      index,
                                                      key,
                                                      "date",
                                                      "from"
                                                    )
                                                  }
                                                >
                                                  <GetHours
                                                    val={value.from.hr}
                                                  />
                                                </select>
                                                :{t("_min")}
                                                <select
                                                  name="mi"
                                                  onChange={(e) =>
                                                    setSelectedTime(
                                                      "fixed_unique_availiblity_date",
                                                      e,
                                                      index,
                                                      key,
                                                      "date",
                                                      "from"
                                                    )
                                                  }
                                                >
                                                  <GetMinutes
                                                    val={value.from.mi}
                                                  />
                                                </select>
                                                <span className="fixed_time-title">
                                                  To
                                                </span>
                                                {t("_hr")}
                                                <select
                                                  name="hr"
                                                  onChange={(e) =>
                                                    setSelectedTime(
                                                      "fixed_unique_availiblity_date",
                                                      e,
                                                      index,
                                                      key,
                                                      "date",
                                                      "to"
                                                    )
                                                  }
                                                >
                                                  <GetHours val={value.to.hr} />
                                                </select>
                                                :{t("_min")}
                                                <select
                                                  name="mi"
                                                  onChange={(e) =>
                                                    setSelectedTime(
                                                      "fixed_unique_availiblity_date",
                                                      e,
                                                      index,
                                                      key,
                                                      "date",
                                                      "to"
                                                    )
                                                  }
                                                >
                                                  <GetMinutes
                                                    val={value.to.mi}
                                                  />
                                                </select>
                                                <span
                                                  className="m-0 cursor-pointer"
                                                  onClick={() =>
                                                    removeUniqueDayTime(
                                                      item[0],
                                                      key,
                                                      "fixed_availiblity"
                                                    )
                                                  }
                                                >
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                  >
                                                    <path
                                                      d="M9.14453 9.42871H10.4302V17.143H9.14453V9.42871Z"
                                                      fill="#fc5301"
                                                    ></path>
                                                    <path
                                                      d="M13 9.42871H14.2857V17.143H13V9.42871Z"
                                                      fill="#fc5301"
                                                    ></path>
                                                    <path
                                                      d="M4 5.57129V6.857H5.28571V19.7141C5.28571 20.0551 5.42117 20.3822 5.66229 20.6233C5.90341 20.8644 6.23044 20.9999 6.57143 20.9999H16.8571C17.1981 20.9999 17.5252 20.8644 17.7663 20.6233C18.0074 20.3822 18.1429 20.0551 18.1429 19.7141V6.857H19.4286V5.57129H4ZM6.57143 19.7141V6.857H16.8571V19.7141H6.57143Z"
                                                      fill="#fc5301"
                                                    ></path>
                                                    <path
                                                      d="M9.14453 3H14.2874V4.28571H9.14453V3Z"
                                                      fill="#fc5301"
                                                    ></path>
                                                  </svg>
                                                </span>
                                              </div>
                                            );
                                          })}
                                        {val[1].length == 0 && (
                                          <p
                                            className="cursor-pointer add-starting-special-date-btn"
                                            onClick={(e) =>
                                              handleWeeklyTime(
                                                index,
                                                "date",
                                                "fixed_availiblity"
                                              )
                                            }
                                          >
                                            <FontAwesomeIcon icon={faClock} />
                                            {t("_add_starting_time")}
                                          </p>
                                        )}
                                      </div>
                                    )}
                                  </Fragment>
                                );
                              })}
                            </div>
                            <FontAwesomeIcon
                              onClick={() =>
                                removeNewDate(index, "fixed_availiblity")
                              }
                              className="removedate"
                              icon={faTimesCircle}
                            />
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
                {/* {
                  formErrors.weekelyTimeError &&


                } */}
                <div className="add_new_date_btn">
                  <span
                    onClick={() => addNewDate("fixed_unique_availiblity_date")}
                    className="pricing_schedule_add_btn"
                  >
                    <FontAwesomeIcon icon={faPlus} />
                    &nbsp;&nbsp;
                    {t("_add_new_date")}
                  </span>
                </div>
              </>
            ) : (
              <>
                <div className="week_time_table_watch">
                  <div className="week_time_table">
                    <h6>{t("_weekly_starting_times")}</h6>
                    {/* <p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting
                        industry. Lorem Ipsum has been the industry's standard dummy
                        text ever since the 1500s, when an unknown printer took a galley
                        of type and scrambled it to make a type specimen book.
                    </p> */}
                    <ul>
                      {formData.weekly_availiblity &&
                        Object.entries(formData.weekly_availiblity).map(
                          (item, index) => {
                            return (
                              <div key={index}>
                                <li className="text-body">
                                  <div className="row">
                                    <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1">
                                      <span>
                                        {" "}
                                        {item[0].charAt(0).toUpperCase() +
                                          item[0].slice(1)}
                                      </span>
                                    </div>
                                    <div className="col-xl-11 col-lg-11 col-md-11 col-sm-11 col-11">
                                      {item[1].length > 0 &&
                                        item[1].map((value, key) => {
                                          return (
                                            <div
                                              key={key}
                                              className="select-main-day"
                                            >
                                              {t("_hr")}
                                              <select
                                                name="hr"
                                                onChange={(e) =>
                                                  setSelectedTime(
                                                    "weekly_availiblity",
                                                    e,
                                                    key,
                                                    item[0],
                                                    "day"
                                                  )
                                                }
                                              >
                                                <GetHours val={value.hr} />
                                              </select>
                                              :{t("_min")}
                                              <select
                                                name="mi"
                                                onChange={(e) =>
                                                  setSelectedTime(
                                                    "weekly_availiblity",
                                                    e,
                                                    key,
                                                    item[0],
                                                    "day"
                                                  )
                                                }
                                              >
                                                <GetMinutes val={value.mi} />
                                              </select>
                                              <span
                                                className="m-0 cursor-pointer"
                                                onClick={() =>
                                                  removeWeakTime(
                                                    key,
                                                    item[0],
                                                    "weekly_availiblity"
                                                  )
                                                }
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="24"
                                                  height="24"
                                                  viewBox="0 0 24 24"
                                                  fill="none"
                                                >
                                                  <path
                                                    d="M9.14453 9.42871H10.4302V17.143H9.14453V9.42871Z"
                                                    fill="#fc5301"
                                                  ></path>
                                                  <path
                                                    d="M13 9.42871H14.2857V17.143H13V9.42871Z"
                                                    fill="#fc5301"
                                                  ></path>
                                                  <path
                                                    d="M4 5.57129V6.857H5.28571V19.7141C5.28571 20.0551 5.42117 20.3822 5.66229 20.6233C5.90341 20.8644 6.23044 20.9999 6.57143 20.9999H16.8571C17.1981 20.9999 17.5252 20.8644 17.7663 20.6233C18.0074 20.3822 18.1429 20.0551 18.1429 19.7141V6.857H19.4286V5.57129H4ZM6.57143 19.7141V6.857H16.8571V19.7141H6.57143Z"
                                                    fill="#fc5301"
                                                  ></path>
                                                  <path
                                                    d="M9.14453 3H14.2874V4.28571H9.14453V3Z"
                                                    fill="#fc5301"
                                                  ></path>
                                                </svg>
                                              </span>
                                            </div>
                                          );
                                        })}
                                      {/* </div> */}
                                      <p
                                        className="cursor-pointer"
                                        onClick={(e) =>
                                          handleWeeklyTime(
                                            item[0],
                                            "day",
                                            "weekly_availiblity"
                                          )
                                        }
                                      >
                                        <FontAwesomeIcon icon={faClock} />{" "}
                                        {t("_add_starting_time")}
                                      </p>
                                    </div>
                                  </div>

                                  {/* <div> */}
                                </li>
                              </div>
                            );
                          }
                        )}
                    </ul>
                  </div>
                </div>

                <div className="special_dates">
                  <h6>{t("_single_special_dates")}</h6>
                </div>
                <div className="pricingSchedule_special_dates">
                  {formData.unique_availiblity_date !== undefined && (
                    <div className="div-height">
                      {Object.entries(formData.unique_availiblity_date).map(
                        (item, index) => {
                          return (
                            <div
                              className="valid_form  single-special-dates position-relative"
                              key={index}
                            >
                              <div className="pic_dates account_calender">
                                {Object.entries(item[1]).map((val, key) => {
                                  return (
                                    <Fragment key={key}>
                                      {val[0] == "date" && (
                                        <Flatpickr
                                          className="date_picker"
                                          placeholder="Select date"
                                          id="validity_from"
                                          name="validity_from"
                                          value={val[1]}
                                          options={{
                                            dateFormat: "Y-m-d",
                                            monthSelectorType: "static",
                                            // minDate: "today"
                                          }}
                                          onChange={(value) =>
                                            handleDate(
                                              value[0],
                                              index,
                                              "unique_availiblity_date"
                                            )
                                          }
                                        />
                                      )}

                                      {/* <div> */}
                                      {val[0] == "time" && (
                                        <div>
                                          {val[1].length > 0 &&
                                            val[1].map((value, key) => {
                                              return (
                                                <div
                                                  key={key}
                                                  className="select-main-day-special-dates"
                                                >
                                                  {t("_hr")}
                                                  <select
                                                    name="hr"
                                                    onChange={(e) =>
                                                      setSelectedTime(
                                                        "unique_availiblity_date",
                                                        e,
                                                        index,
                                                        key,
                                                        "date"
                                                      )
                                                    }
                                                  >
                                                    <GetHours val={value.hr} />
                                                  </select>
                                                  :{t("_min")}
                                                  <select
                                                    name="mi"
                                                    onChange={(e) =>
                                                      setSelectedTime(
                                                        "unique_availiblity_date",
                                                        e,
                                                        index,
                                                        key,
                                                        "date"
                                                      )
                                                    }
                                                  >
                                                    <GetMinutes
                                                      val={value.mi}
                                                    />
                                                  </select>
                                                  <span
                                                    className="m-0 cursor-pointer"
                                                    onClick={() =>
                                                      removeUniqueDayTime(
                                                        item[0],
                                                        key,
                                                        "weekly_availiblity"
                                                      )
                                                    }
                                                  >
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="24"
                                                      height="24"
                                                      viewBox="0 0 24 24"
                                                      fill="none"
                                                    >
                                                      <path
                                                        d="M9.14453 9.42871H10.4302V17.143H9.14453V9.42871Z"
                                                        fill="#fc5301"
                                                      ></path>
                                                      <path
                                                        d="M13 9.42871H14.2857V17.143H13V9.42871Z"
                                                        fill="#fc5301"
                                                      ></path>
                                                      <path
                                                        d="M4 5.57129V6.857H5.28571V19.7141C5.28571 20.0551 5.42117 20.3822 5.66229 20.6233C5.90341 20.8644 6.23044 20.9999 6.57143 20.9999H16.8571C17.1981 20.9999 17.5252 20.8644 17.7663 20.6233C18.0074 20.3822 18.1429 20.0551 18.1429 19.7141V6.857H19.4286V5.57129H4ZM6.57143 19.7141V6.857H16.8571V19.7141H6.57143Z"
                                                        fill="#fc5301"
                                                      ></path>
                                                      <path
                                                        d="M9.14453 3H14.2874V4.28571H9.14453V3Z"
                                                        fill="#fc5301"
                                                      ></path>
                                                    </svg>
                                                  </span>
                                                </div>
                                              );
                                            })}
                                          <p
                                            className="cursor-pointer add-starting-special-date-btn"
                                            onClick={(e) =>
                                              handleWeeklyTime(
                                                index,
                                                "date",
                                                "weekly_availiblity"
                                              )
                                            }
                                          >
                                            <FontAwesomeIcon icon={faClock} />
                                            {t("_add_starting_time")}
                                          </p>
                                        </div>
                                      )}
                                    </Fragment>
                                  );
                                })}
                              </div>
                              <FontAwesomeIcon
                                onClick={() =>
                                  removeNewDate(index, "weekly_availiblity")
                                }
                                className="removedate"
                                icon={faTimesCircle}
                              />
                            </div>
                          );
                        }
                      )}
                    </div>
                  )}
                </div>
                <div className="add_new_date_btn">
                  <span
                    onClick={() => addNewDate("unique_availiblity_date")}
                    className="pricing_schedule_add_btn"
                  >
                    <FontAwesomeIcon icon={faPlus} />
                    &nbsp;&nbsp;
                    {t("_add_new_date")}
                  </span>
                </div>
              </>
            )
          }

          {formErrors.weekelyTimeError && (
            <div>
              <p className="availbilty-note mt-2">
                Select atleast one weekely starting time to proceed.
              </p>
            </div>
          )}
        </>
      )}
    </div>
  );
};
export default PricingScheduleStepTwo;
